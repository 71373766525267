<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="New Data Source" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="dataSource.dataSourceName"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Data Source Name" />
          </div>
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="dataSource.url"
              :min="1"
              type="url"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeUrl"
              label="Url" />
          </div>
          <div class="column is-4">
            <div class="form-group">
              <p class="display-flex">
                Modules<sup class="display-flex required">*</sup>
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutsideModule">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="modules"
                    @focus="onFocusModule"
                    :icon-right="openModule ? 'menu-up' : 'menu-down'"
                    v-model="searchModule"
                    class="form-control" />
                    <div v-if="submitted && !this.dataSource.moduleId.length" class="required-error">
                      Modules are required
                    </div>
                  <common-selection-vue
                    :searchText="searchModule"
                    name="module_name"
                    @focus="onFocusModule"
                    :open="openModule"
                    :selected="dataSource.moduleId"
                    @onChange="onChangeModuleId"
                    :list="moduleList.filter(m => m.module_name !== 'settings')" />
                </div>
                <closable-tags-vue
                  :canClose="isEditable"
                  @onChange="onRemoveModuleName"
                  name="module_name"
                  :selected="dataSource.moduleId" />
              </dropdown-wrap-vue>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'
import ClosableTagsVue from '../../components/ClosableTags.vue'

/** This component contains the code to add a new data source to the database */
export default {
  name: 'AddDataSource',
  components: {
    AdminHeaderVue,
    InputVue,
    DropdownWrapVue,
    CommonSelectionVue,
    ClosableTagsVue
  },
  props: ['isEditable'],
  data () {
    return {
      dataSource: {
        dataSourceName: null,
        url: null,
        moduleId: []
      },
      submitted: false,
      isValidName: false,
      isValidUrl: false,
      openModule: false,
      searchModule: null
    }
  },
  computed: {
    ...mapState('modules', ['moduleList'])
  },
  mounted () {
    this.getModuleList()
  },
  methods: {
    ...mapActions('dataSource', ['addDataSource']),
    ...mapActions('modules', ['getModuleList']),
    /** This method defines the behavior for when the data source name (Input value) is changed
     * @param name {String} - The name of the new data source
     * @param isValid {Boolean}
     * @public
     */
    onChangeName (name, isValid) {
      this.dataSource.dataSourceName = name
      this.isValidName = isValid
    },
    /** This method defines the behavior for when the data source url (Input value) is changed
     * @param url {String} - The url of the new category
     * @param isValid {Boolean}
     * @public
     */
    onChangeUrl (url, isValid) {
      this.dataSource.url = url
      this.isValidUrl = isValid
    },
    onFocusModule () {
      this.$refs.modules.$el.firstChild.focus()
      this.openModule = true
    },
    onChangeModuleId (moduleId, isValid) {
      this.dataSource.moduleId = moduleId
    },
    onClickOutsideModule () {
      this.openModule = false
    },
    onRemoveModuleName (object) {
      this.dataSource.moduleId = this.dataSource.moduleId.filter(
        (id) => id.module_id !== object.module_id
      )
    },
    /** This method validates the input and submits the form
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (!(this.isValidName || this.isValidUrl) && this.dataSource.moduleId.length) {
        this.addDataSource(this.dataSource)
      }
    },
    /** This method redirects the user back to the data source page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/source')
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
   margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .upload {
          width: 200px;
          height: 60px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          img {
            width: 200px;
            height: 60px;
            object-fit: contain;
            padding: 5px;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
